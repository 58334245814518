import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../components/auth/auth.service';

import {IMyDpOptions, IMyDateModel} from 'mydatepicker';


/*
+--------------------+------------------+------+-----+---------+----------------+
| Field              | Type             | Null | Key | Default | Extra          |
+--------------------+------------------+------+-----+---------+----------------+
| id                 | int(10) unsigned | NO   | PRI | NULL    | auto_increment |
| id_company         | int(10) unsigned | NO   | MUL | NULL    |                |
| nome               | varchar(45)      | YES  |     | NULL    |                |
| cognome            | varchar(45)      | YES  |     | NULL    |                |
| livello            | varchar(20)      | YES  |     | NULL    |                |
| data_visita_medica | date             | YES  |     | NULL    |                |
| dipendente         | int(1)           | YES  |     | NULL    |                |
| stato              | bit(1)           | YES  |     | NULL    |                |
| created_at         | datetime         | YES  |     | NULL    |                |
| updated_at         | datetime         | YES  |     | NULL    |                |
| id_user            | int(11)          | YES  | MUL | NULL    |                |
| data_nascita       | date             | YES  |     | NULL    |                |
| luogo_nascita      | varchar(250)     | YES  |     | NULL    |                |
| codice_fiscale     | varchar(250)     | YES  |     | NULL    |                |
+--------------------+------------------+------+-----+---------+----------------+

*/

interface Employee {
    id: number;
    id_user: number;
    id_company: number;
    nome: string;
    cognome: string;
    data_nascita: Date;
    data_nascita_js: any;
    luogo_nascita: string;
    codice_fiscale: string;
    type: string;
    livello: string;
    data_visita_medica: Date;
    data_visita_medica_js: any;
    dipendente: boolean;
    stato: boolean;
    created_at: string;
    updated_at: string;
    Securitytasks: Securitytask[];
    documents: Document[];
    errors: string[];
    valid: boolean;
}

interface Securitytask {
    id: number;
    name: string;
}

interface Document {
    id: number;
    type: string;
}

@Component({
    selector: 'Employee',
    template: require('./employee.pug'),
    styles: [require('./employee.css')],
})

export class EmployeeComponent implements OnInit {
    public dateOptions: IMyDpOptions = {
        dateFormat: 'dd-mm-yyyy',
        dayLabels:	{su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mer', th: 'Gio', fr: 'Ven', sa: 'Sab'},
        monthLabels:	{ 1: 'Gen', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'Mag', 6: 'Giu', 7: 'Lug', 8: 'Ago', 9: 'Set', 10: 'Ott', 11: 'Nov', 12: 'Dic' },
        todayBtnTxt: "Oggi",
        maxYear: 2030
    };

    types=[
        'Titolare operante',
        'Dipendente',
        'Dipendente non operante in cantiere',
        'Consulente esterno',
        'Coadiuvante familiare ',
        'Socio lavoratore',        
    ]

    documentTypesBySecuritytask = [
        { securitytask: "Capo Cantiere", documentTypes: ['CONSEGNA D.P.I.',"IDONEITA' MANSIONE"]},
        { securitytask: "Direttore tecnico di Cantiere", documentTypes: ['CONSEGNA D.P.I.',"IDONEITA' MANSIONE"]},
        { securitytask: "Preposto", documentTypes: ['ATTESTATO PREPOSTO','CONSEGNA D.P.I.',"IDONEITA' MANSIONE"]},
        { securitytask: "Patentino Ponteggio", documentTypes: ['PATENTINO PONTEGGIO','CONSEGNA D.P.I.',"IDONEITA' MANSIONE"]},
        { securitytask: "Addetto Pronto Soccorso", documentTypes: ['CORSO PRIMO SOCCORSO','CONSEGNA D.P.I.',"IDONEITA' MANSIONE"]},
        { securitytask: "Addetto antincendio", documentTypes: ['CORSO ANTI INCENDIO','CONSEGNA D.P.I.',"IDONEITA' MANSIONE"]},
        { securitytask: "Preposto Ponteggio", documentTypes: ['PATENTINO PONTEGGIO','CONSEGNA D.P.I.',"IDONEITA' MANSIONE"]},
        { securitytask: "R.L.S.", documentTypes: ['FORMAZIONE R.L.S.','NOMINA R.L.S.']},
        { securitytask: "R.S.P.P.", documentTypes: ['ATTESTATO R.S.P.P.','NOMINA R.S.P.P.']},
        { securitytask: "Medico Competente", documentTypes: ['NOMINA MEDICO COMPETENTE']},
        { securitytask: "allEmployees", documentTypes: ['CONSEGNA D.P.I.',"IDONEITA' MANSIONE",'ATTESTATO DI FORMAZIONE']},
    ] 

    documentTypesNotCovered: string[];

    employee: Employee;
    
    errors: {field?: Error} = {};
    submitted = false;
    
    editSecuritytasks = true
    expiringDays = '';
    
    static parameters = [HttpClient, Router, ActivatedRoute, AuthService];
    constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute,private authService: AuthService) {
        this.http = http;
        this.router = router;
    }

    ngOnInit() {    
        this.route.paramMap.subscribe( params => {
            var id: string = params.get('id');
            this.http.get('/api/employees/'+id).subscribe( (c: Employee) => {
                this.employee = c;
                this.setDates();
                this.updateDocumentTypesNotCovered();
            });
        });
	this.expiringDays = localStorage.getItem('expiringDays');
        if( parseInt( this.expiringDays) <0 ){
          alert("Personale non modificabile, account scaduto da "+ Math.abs(parseInt(this.expiringDays)) + " giorni.");
        }

    }

    setDates(){
        if(!this.employee.data_nascita){
            this.employee.data_nascita = new Date();
        }else{
            this.employee.data_nascita = new Date(this.employee.data_nascita)
        }
        if(!this.employee.data_visita_medica){
            this.employee.data_visita_medica = new Date();
        }else{
            this.employee.data_visita_medica = new Date(this.employee.data_visita_medica)
        }

        this.employee.data_nascita_js = { 'date': { year: this.employee.data_nascita.getFullYear(), 'month': this.employee.data_nascita.getMonth() + 1, 'day': this.employee.data_nascita.getDate()+1} };
        this.employee.data_visita_medica_js = { 'date': { year: this.employee.data_visita_medica.getFullYear(), 'month': this.employee.data_visita_medica.getMonth() + 1, 'day': this.employee.data_visita_medica.getDate()+1} };
    }

    onDataNascitaChanged(event: IMyDateModel) {
        this.employee.data_nascita = event.jsdate;
        this.employee.data_nascita.setDate( this.employee.data_nascita.getDate() + 1);

        this.updateEmployee();
    }
    onDataVisitaMedicaChanged(event: IMyDateModel) {
        this.employee.data_visita_medica = event.jsdate;
        this.employee.data_visita_medica.setDate( this.employee.data_visita_medica.getDate() + 1);

        this.updateEmployee();
    }

    updateEmployee() {
        this.http.put('/api/employees/'+this.employee.id, this.employee).subscribe( (e: Employee) => {
            this.employee.errors = e.errors;
            this.employee.valid = e.valid;
            console.log('Updated Employee!');
        });
    }


    deleteEmployee(employee) {
        return this.http.delete(`/api/employees/${employee._id}`)
            .subscribe(() => {
                console.log('Deleted Employee');
            });
    }

    onDipendendeFlagChange(isDipendente){
        this.employee.dipendente = isDipendente;
        this.updateEmployee();
    }

    onSecuritytasksSelectedChanges( securitytasks: Securitytask[]){
        this.employee.Securitytasks = securitytasks;
        this.updateEmployee();
        this.updateDocumentTypesNotCovered();        
    }
    
    updateDocumentTypesNotCovered(){
        var documentTypesPresent = [];
        this.employee.documents.forEach(d => {
            documentTypesPresent.push(d.type);
        });

        this.documentTypesNotCovered = [];
        this.employee.Securitytasks.forEach( st => {
            this.documentTypesBySecuritytask.forEach( dt => {
                if(dt.securitytask == st.name){
                    dt.documentTypes.forEach(a => {
                        if( documentTypesPresent.indexOf(a) < 0 && this.documentTypesNotCovered.indexOf(a) < 0 ){
                            this.documentTypesNotCovered.push( a );
                        }
                    });
                }
            })
        });
    }

    completed(){
        const url = '/employees';
        this.router.navigate([url]);
    }


    downloadDocument(doc) {
        doc.downloading = true;
        doc.error = false;
        this.http.get('/api/documents/'+doc.id+'/download', {responseType: 'blob'} ).subscribe( data => {
            var blob = new Blob([data], {type: 'application/pdf'});
            var downloadURL = window.URL.createObjectURL(data);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = "document_"+doc.id+".pdf";
            link.click();
            doc.downloading = false;
            doc.error = false;
        }, error => {
            doc.downloading = false;
            doc.error = true;
        });
    }

    addEmployeeDocument(){
        this.http.post('/api/documents', { type:'DA DEFINIRE', filename: 'NUOVO FILE', data_documento: '2020-01-01', id_employee: this.employee.id}).subscribe( (d: Document) => {
            console.log('Added Document:', d);
            const url = '/document/'+d.id;
            this.router.navigate([url]);
        });
    }

    delete(){
        var r = confirm("Stai per cancellare un dipendente/collaboratore. Premi OK per procedere"); 
        if (r == true) {
            this.http.delete('/api/employees/'+ this.employee.id).subscribe( () => {
                this.completed();
            });
        } 
    }
}
