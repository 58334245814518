import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Router, ActivatedRoute, Params} from '@angular/router';
import {IMyDpOptions, IMyDateModel} from 'mydatepicker';
import { AuthService } from '../../components/auth/auth.service';
import * as $ from 'jquery';


interface Workingtooltype {
  id: number;
  name: string;
}


interface Workingtool {
  id: number;
  brand: string;
  model: string;
  documents: any[];
}

interface Employeetype {
  id: number;
  name: string;
}

interface Phase {
  id: number;
  name: string;
  description: string;
  isEditing: boolean;
  Subphases: any[];
}

interface Subphase {
  id: number;
  name: string;
  description: string;
  isEditing: boolean;
  Risks: any[];
}

interface Pos {
    id: number;
    info?: string;
    id_company: number;
    fulladdress: string;
    address1: string;
    data_documento: Date;
    data_documento_js: any;
    operanti : string;
    subappalti : string;
    documenti : string;
    contesto : string;
    fasi_lavorative :  string;
    macchine : string;
    figure : string;
    array_qualifiche : string;
    desc_sintetica : string;
    dettagli : string;
    responsabile_lavori : string;
    committente : string;
    appaltatrice : string;
    coordinatore_sicurezza : string;
    direttore_lavori : string;
    prot : string;
    rev : string;
    deleted: boolean;
    workingtools: Workingtool[];
    Workingtooltypes: Workingtooltype[];
    Employeetypes: Employeetype[];
    Company: Company;
    Subcompanies: any[];
    Employees: any[];
    documents: any[];
    Phases: any[];
    errors: string[];
    valid: boolean;
    automate: boolean;
}

interface EmployeeInSecuritytask {
    id: number;
    nome: string;
    cognome: string;
    securitytaskname: string;
    securitytaskid: number;
    checked: boolean;
}

interface Company {
  id: number;
  name: string;
  Employees: Employee[];
  documents: any[];
  Workingtooltypes: Workingtooltype[];
}

interface Employee {
    id: number;
    nome: string;
    cognome: string;
    datore_lavoro: boolean;
    Securitytasks: Securitytask[];
    in_site: boolean;
    deleted: boolean;
    documents: any[];
    type: string;
}

interface Securitytask {
    id: number;
    name: string;
    nominatedEmployees: EmployeeInSecuritytask[];
    in_site: boolean;
}


@Component({
    selector: 'pos',
    template: require('./pos.pug'),
    styles: [require('./pos.css')],
})

export class PosPageComponent implements OnInit, OnDestroy {

  public dateOptions: IMyDpOptions = {
    dateFormat: 'dd-mm-yyyy',
    dayLabels:	{su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mer', th: 'Gio', fr: 'Ven', sa: 'Sab'},
    monthLabels:	{ 1: 'Gen', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'Mag', 6: 'Giu', 7: 'Lug', 8: 'Ago', 9: 'Set', 10: 'Ott', 11: 'Nov', 12: 'Dic' },
    todayBtnTxt: "Oggi",
    maxYear: 2030
  };

  lotto_isolato = "Il lotto è in contesto isolato . Non vi sono interferenze con edifici  vicini. L'ingresso avviene da passo carraio, presente viabilità interna e luogo per la sosta dei veicoli.";
  contesto_urbano =	 "Il lotto confina con edifici esistenti . L'accesso avviene dalla via pubblica e non è prevista viabilità interna";
  appartamento = 	"Le lavorazioni avverranno in contesto condominiale ed interessano una singola unità immobiliare. Le interferenze con i condomini sono limitate alla movimentazione dei carichi che avverranno dalle scale o con l'ausilio di arganello posizionato su finestra.";
  condominio_su_strada =	"Si tratta di condominio con fronte su strada in ambiente altamente urbanizzato. Non è previsto accesso carraio.";

  
  contestoValues = [
    this.lotto_isolato,
    this.contesto_urbano,
    this.appartamento,
    this.condominio_su_strada,
  ]

  pos: Pos;
  securitytasks: any[];
  allSubcompanies: any[];

  phaseInEdit: Phase;
  subphaseInEdit: Subphase;

  step: number = 9;
  
  editEmployeetypes = true;
  editWorkingtooltypes = true;
  editWorkingtools = true;
  editDocuments = true;
  editEmployees = true;
  editSubappalti = true;
  expiringDays =  '';


  static parameters = [HttpClient, Router,  ActivatedRoute];
  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private authService: AuthService) {
      this.http = http;
      this.route = route;
      this.authService = authService;
  }

  ngOnInit() {
      this.expiringDays = localStorage.getItem('expiringDays');
      this.route.paramMap.subscribe( params => {
          var id: string = params.get('id');
          this.http.get('/api/pos/'+id).subscribe((pos: Pos) => {
              this.pos = pos;
              this.setDate();
              this.setEmployeesInSite();
              this.selectOffSiteEmployees();
              if(!this.pos.prot){
                this.pos.prot = this.pos.id.toString();
                this.pos.rev = '1';
              }
              this.pos.workingtools.forEach(e =>{
                this.autoSelectDocuments(e.documents)
              });
              this.updatePos( () =>{
                this.autoSelectDocuments(this.pos.Company.documents);
                this.pos.Employees.forEach(e =>{
                  this.autoSelectDocuments(e.documents)
                })
                this.updatePos(false);
              });
              this.http.get('/api/companies/').subscribe( (list: any) => {
                var list1 = list.filter( o => {return o.subcompany}); //prendo solo le subcompanies
                var list2 = list1.filter( o => {return o.id_parent_company === this.pos.Company.id}); //prendo solo 
                this.allSubcompanies = list2;
              });
              this.http.get('/api/securitytasks').subscribe((securitytasks: any[]) => {
                this.securitytasks = securitytasks;
                this.updateSecuritytasks();
              });
          });
      });
      if( parseInt( this.expiringDays) <0 ){ 
        alert("POS non modificabile, account scaduto da "+ Math.abs(parseInt(this.expiringDays)) + " giorni.");
      }
  }


  setDate(){
    if(!this.pos.data_documento){
        this.pos.data_documento = new Date();
    }else{
        this.pos.data_documento = new Date(this.pos.data_documento)
    }
    this.pos.data_documento_js = { 'date': { year: this.pos.data_documento.getFullYear(), 'month': this.pos.data_documento.getMonth() + 1, 'day': this.pos.data_documento.getDate()} };
  }

  onDocumentDateChanged(event: IMyDateModel) {
    this.pos.data_documento = event.jsdate ;
    this.pos.data_documento.setDate( this.pos.data_documento.getDate() + 1);
    this.updatePos(false);
  }

  selectOffSiteEmployees(){
    this.pos.Employees = this.removeElementsDeletedInArray(this.pos.Employees);
    this.pos.Company.Employees.forEach( companyEmployee => {
      if(!companyEmployee.deleted){
        var isRSPP = this.employeeHasSecurityTask( companyEmployee, 'R.S.P.P.');
        var isRSL = this.employeeHasSecurityTask( companyEmployee, 'R.L.S.');
        var isMedico = this.employeeHasSecurityTask( companyEmployee, 'Medico Competente');
        var isDatore = companyEmployee.datore_lavoro;
        if( (isRSPP  || isRSL || isMedico || isDatore) && !this.inArrayById(this.pos.Employees, companyEmployee)){
          this.pos.Employees.push(companyEmployee);
        }
      } 
    });
  }

  employeeHasSecurityTask(employee, securitytaskName){
    var out = false;
    employee.Securitytasks.forEach( st => {
      if(st.name == securitytaskName){
        out = true;
      }
    });
    return out;
  }

  setEmployeesInSite(){
    this.pos.Company.Employees.forEach(e => {
      this.setEmployeeInSite(e);
    })
    this.pos.Employees.forEach(e => {
      this.setEmployeeInSite(e);
    })
    this.pos.Company.Employees.sort((a, b) => a.cognome.localeCompare(b.cognome));
    this.pos.Employees.sort((a, b) => a.cognome.localeCompare(b.cognome));
  }

  setEmployeeInSite(employee: Employee){
    if( employee.type=='Dipendente non operante in cantiere'){
        employee.in_site = false;
        return;
    }
    if( employee.type=='Dipendente'){
        employee.in_site = true;
        return;
    }
    if(employee.Securitytasks.length == 0 && employee.datore_lavoro){
        employee.in_site = false;
        return;
    }
    if(employee.Securitytasks.length == 0 ){
        employee.in_site = true;
        return;
    } else{
        var offSite = true;
        employee.Securitytasks.forEach( st => {
            if(st.in_site){
                offSite = false;
            }
        })
        employee.in_site = !offSite; 
        return;
    }
  }

  ngOnDestroy() {
  }

  fillContesto(pos){
    this.pos.contesto += '\n\n'+this.contestoValues[pos];
  }
  
  onAddressChange( address: string) {
      this.pos.fulladdress = address;
      this.updatePos(false);
  }

  onWorkingtoolsSelectedChanges( list: Workingtool[]) {
    this.pos.workingtools = list;
    this.updatePos( () =>{
      this.pos.workingtools.forEach(e =>{
        this.autoSelectDocuments(e.documents)
      });
      this.updatePos(false);
    });
  }

  onWorkingtooltypesSelectedChanges( wtsSelected: Workingtooltype[]) {
      this.pos.Workingtooltypes = wtsSelected;
      this.updatePos(false);
  }

  onEmployeetypesSelectedChanges( etsSelected: Employeetype[]) {
      this.pos.Employeetypes = etsSelected;
      this.updatePos(false);
  }

  onEmployeesSelectedChanges( eSelected: any[]) {
      this.pos.Employees = eSelected;
      this.updateSecuritytasks();
      this.updatePos( () => {
        this.pos.Employees.forEach(e =>{
          this.autoSelectDocuments(e.documents)
        })
        this.updatePos(false);
      });
  }

  autoSelectDocuments(docList){
    if(docList && this.pos.automate){
      docList.forEach(d => {
        if(!d.deleted){
          d.checked = true;
          this.pos.documents.push(d);  
        }
      });
    }
  }

  hasSecuritytask(employee, securitytask){
    if (employee && employee.Securitytasks && securitytask){
      return this.inArray(employee.Securitytasks, st => {
        return (st.id === securitytask.id);
      });
    }
    else{
      return false;
    }
  }

  updateSecuritytasks(){
      if(!this.securitytasks){
          return;
      }
      this.setEmployeesInSite();
      this.securitytasks.forEach( st => {
        st.nominatedEmployees = [];
        st.employees = [];
        if(this.pos.Employees){
            this.pos.Employees.forEach( e => {
              if(e.Securitytasks){
                  e.Securitytasks.forEach( est => {
                    if(est.id === st.id){
                      var o = {
                        'id' : e.id,
                        'nome' : e.nome,
                        'cognome' : e.cognome,
                        'securitytaskname' : st.name,
                        'securitytaskid' : st.id,
                        
                      }
                      st.nominatedEmployees.push(o as EmployeeInSecuritytask);
                    }
                  });
                }
              });
        }
      });
  }

  inArray(array, comparer) {
    for(var i=0; i < array.length; i++) {
      if(comparer(array[i])){
        return true;
      }
    }
    return false;
  };

  inArrayById(array, item) {
    for(var i=0; i < array.length; i++) {
      if(array[i].id === item.id){
        return true;
      }
    }
    return false;
  };

  removeElementsDeletedInArray(array) {
    var newArray = [];
    for(var i=0; i < array.length; i++) {
      if(!array[i].deleted){
        newArray.push(array[i]);
      }
    }
    return newArray;
  };


  filterChecked(e: EmployeeInSecuritytask){
    return e.checked;
  }

  filterUnchecked(e: EmployeeInSecuritytask){
    return !e.checked;
  }

  onSecuritytasksSelectedChanges( securitytasks: any[]) {
      this.securitytasks = securitytasks;
      this.updatePos(false);
  }

  onDocumentsSelectedChanges( documents: any[]) {
      this.pos.documents = documents;
      this.updatePos(false);
  }

  doTextareaValueChange(ev, obj) {
    try {
      //console.info(obj);
      obj = ev.target.value;
      //console.info(obj);
      this.updatePos(false);
    } catch(e) {
      console.info('could not set textarea-value');
    }
  }

  
  arrayRemove(array, o) {
       return array.filter(e => {
           return e.id != o.id 
       });
  }
 
  onAddedPhase( addedPhase: any) {
        this.http.post('/api/phases/', {id_parent: addedPhase.id} ).subscribe( (phaseCreated: any)  => {
            console.log(" ---- phaseCreated ---- ");
            this.http.put('/api/pos/'+this.pos.id+'/phases', phaseCreated).subscribe( (pos: any) => {
                console.log("pos e fase associati");
                this.loadPhases(false);
            });
        })
  }

  onAddedSubphase( subphase: any) {
    this.http.post('/api/subphases/', {id_parent: subphase.id}).subscribe( (subphaseCreated: any)  => {
        console.log('Created subphase:', subphaseCreated);
        this.phaseInEdit.Subphases.push(subphaseCreated);
        this.updatePhase(this.phaseInEdit);          
    });  
  }

  loadPhases(callback) {
      this.http.get('/api/pos/'+this.pos.id+'/phases').subscribe( (posLoaded: any) => {
          this.pos.Phases = posLoaded.Phases;
          if(callback){
            callback()
          }
      });
  }
 
  onSubcompaniesSelectedChanges(subcompanies){
      this.pos.Subcompanies = subcompanies;
      this.updatePos(false);
  }

  editPhase(phase: Phase){
    this.phaseInEdit = phase;
  }

  editSubphase(phase: Phase, subphase: Subphase){
    this.phaseInEdit = phase;
    this.subphaseInEdit = subphase;
  }



  deletePhase(phase: any){
      this.http.delete('/api/pos/'+this.pos.id+'/phases/'+phase.id).subscribe( (pos: any) => {
          console.log("pos e fase disassociati");
          //this.loadPhases();
      });

      var phasesNew = []
      this.pos.Phases.forEach(p => {
          if (p.id != phase.id){
              phasesNew.push(p);
          }
      });
      this.pos.Phases = phasesNew;
  }
  
  deleteSubphase(phase: any, subphase: any){
    phase.Subphases = this.arrayRemove(phase.Subphases, subphase);
    this.http.delete('/api/subphases/'+subphase.id, subphase).subscribe( (subphaseDeleted) => {
         console.log('Deleted subphase:', subphaseDeleted);
         this.updatePhase(phase);
    });  
  }

  updatePos(callback){
    this.http.put('/api/pos/'+this.pos.id, this.pos).subscribe( (posUpdated: Pos) => {
      this.pos.errors = posUpdated.errors;
      if(callback){
        this.http.get('/api/pos/'+this.pos.id).subscribe((pos: Pos) => {
          this.pos = pos;
          this.setEmployeesInSite();
          this.selectOffSiteEmployees();
          callback();
        });
      }
    });  
  }

  updatePhase(phase: any){
    this.http.put('/api/phases/'+phase.id, phase).subscribe( (phaseUpdated) => {
      console.log('Updated phase:', phaseUpdated);
    });          
  }

    
  addSubphase(phase: Phase){
    const subphase = {id_parent: 20, position: phase.Subphases.length };
    this.http.post('/api/subphases/', subphase).subscribe( (subphaseCreated: any)  => {
        console.log('Created subphase:', subphaseCreated);
        subphaseCreated.isEditing = true;
        subphaseCreated.Risks = [];
        phase.Subphases.push(subphaseCreated);
        this.updatePhase(phase);
        this.editSubphase(phase,subphaseCreated)       
        $("#modalEditSubphase").modal('show');
    });  
  }

  moveSubphase(phase, oldIndex, newIndex){
    this.array_move( phase.Subphases, oldIndex, newIndex);
    this.updatePhase(phase);
  }

  movePhase(oldIndex, newIndex){
      this.array_move( this.pos.Phases, oldIndex, newIndex);
      this.updatePos(false);
  }
  
  array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
          var k = new_index - arr.length + 1;
          while (k--) {
              arr.push(undefined);
          }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  };
  
  createNewPhase(){
      this.http.post('/api/phases/', {id_parent: 157} ).subscribe( (phaseCreated: any)  => {
          console.log(" ---- phaseCreated ---- ");
          this.http.put('/api/pos/'+this.pos.id+'/phases', phaseCreated).subscribe( (pos: any) => {
              console.log("pos e fase associati");
              this.loadPhases( () => {
                this.editPhase(this.pos.Phases[this.pos.Phases.length-1])       
                $("#modalEditPhase").modal('show');
              });
          });
      })
  }

  completed(){
    this.pos.automate = false;
    this.updatePos(false);
    const url = '/posList';
    this.router.navigate([url]);
  }


  delete(){
    var r = confirm("Stai per cancellare un POS. Premi OK per procedere"); 
    if (r == true) {
        this.http.delete('/api/pos/'+ this.pos.id).subscribe( () => {
            this.completed();
        });
    } 
  }

  clone(){
    var r = confirm("Stai per clonare il POS. Premi OK per procedere"); 
    if (r == true) {
        this.http.post('/api/pos', { id_company: this.pos.Company.id }).subscribe( (posNew: Pos) => {
            console.log('Added Pos:'+ posNew.id);
            this.pos.prot = ''+posNew.id;
            this.pos.rev = '1';
            this.http.put('/api/pos/'+posNew.id, this.pos).subscribe( (posUpdated: Pos) => {
              const url = '/pos/'+posNew.id;
              this.router.navigate([url]);
            });
        });
        this.completed();
    } 
  }

  notDeleted(list){
    return list.filter(x => !x.deleted);
  }
}
